import { Fragment, useEffect, useState } from "react";
import { createMission, updateMission } from "../API";
import { Field, useFormik } from "formik";

import { useMediaQuery } from "@mui/material";
import { FormControl, TextField, Autocomplete } from "@mui/material";

import Loader from "./Loader";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

function AddMissionsModal({ modalOpen, setModalOpen, locationList, mission }) {
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const token = localStorage.getItem("token");
  const [missionId, setMissionId] = useState(mission?mission?._id:null);
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));

  // const [missionId, setMissionId] = useState(mission._id);
  const toastmsg = (msg) => toast(msg);

  // const validationSchema = Yup.object({
  //     name: Yup.string().required('Name is required'),
  //     actions: Yup.array().of(
  //       Yup.object({
  //         name: Yup.string().required('Action name is required'),
  //         id: Yup.string().required('Action ID is required'),
  //         timestamp: Yup.string().required('Timestamp is required'), // Add timestamp validation
  //       })
  //     ),
  //   });

  const actionBody = {
    actionType: "",
    message: "",
    locationId: null,
    startTime: "",
    scheduled: true,
    finishTimeCalculated: "",
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      actions: [actionBody],
      status: true,
      isDeleted: false,
      fleetId: fleetData.fleetId,
      autoStart: true,
      type: "scheduled",
      recurrance: {
        status: true,
        recurranceType: "DAILY",
      },
    },
    onSubmit: (values, { resetForm }) => {
      let isValidated = true;

      values.actions.map((item) => {
        if (isValidated && item.startTime) {
          let hour = "00",
            min = `00`;
          hour = item.startTime.substring(0, 2);
          min = item.startTime.substring(2, 4);
          if (
            item.startTime.length > 4 ||
            parseInt(hour) > 23 ||
            parseInt(hour) < 0 ||
            parseInt(min) > 59 ||
            parseInt(min) < 0
          ) {
            isValidated = false;
            console.log("first valid not");
            return;
          }
        }
        if (isValidated && item.finishTimeCalculated) {
          let hour = "00",
            min = `00`;
          hour = item.finishTimeCalculated.substring(0, 2);
          min = item.finishTimeCalculated.substring(2, 4);
          if (
            item.finishTimeCalculated.length > 4 ||
            parseInt(hour) > 23 ||
            parseInt(hour) < 0 ||
            parseInt(min) > 59 ||
            parseInt(min) < 0
          ) {
            console.log("second valid not");
            isValidated = false;
            return;
          }
        }

        if (
          isValidated &&
          parseInt(item.startTime) > parseInt(item.finishTimeCalculated)
        ) {
          
          console.log(parseInt(item.startTime) , parseInt(item.finishTimeCalculated), "third valid not");
          isValidated = false;
          return;
        }

        return;
      });
      console.log("isValidated", isValidated);
      if (!isValidated) {
        toast.error("Invalid time");
        console.log("validation failed");
        return;
      }

      if(mission){
        updateMission(values, missionId, token).then((res) => {
          toast.success("Mission updated successfully");
          console.log(res, "res");
        });
      }
      else{
        createMission(values, token).then((res) => {
          toast.success("Mission created successfully");
          console.log(res, "res");
        });
      }


      resetForm({});
      setModalOpen(false);
    },
  });

  useEffect(() => {
    if(mission){
      formik.setValues(mission);
    }
  }, []);

  const modalstyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#282F42",
    border: "2px solid #000",
    boxShadow: 24,
  };
  const actionTypes = [
    {
      label: "Move and Pickup",
      value: "MOVELOAD",
    },
    {
      label: "Move and Drop",
      value: "MOVEUNLOAD",
    },
    // {
    //   label: "Pickup",
    //   value: "PICKUP",
    // },
    // {
    //   label: "Drop",
    //   value: "DROP",
    // },
    {
      label: "Move To Home",
      value: "MOVEHOME",
    },
  ];

  const handleActionTypeChange = (selectedOption, val, index) => {
    // val?.locationId?.locationName

    let newVal = { ...val };

    if (!selectedOption) {
      return;
    }

    if (selectedOption.label === "Move and Drop") {
      let loadCount = formik.values.actions.filter((item) => {
        return item.message === "MOVELOAD";
      }).length;

      if (loadCount < 1 || (loadCount === 1 && newVal.message === "MOVELOAD")) {
        toast.error("Please Add Load First");
        return;
      }
    }

    newVal = {
      ...newVal,
      message: selectedOption?.value,
    };

    formik.setFieldValue(
      "actions",
      formik.values.actions.map((item, idx) => {
        if (index === idx) {
          return {
            ...item,
            actionType: "MOVELOCATION",
            message: selectedOption?.value,
          };
        }
        return item;
      })
    );
  };

  const handleTimeChange = (e, index, action, name) => {
    let val = { ...action };

    val[`${name}`] = e.target.value;

    formik.setFieldValue(
      "actions",
      formik.values?.actions.map((item, idx) => {
        if (idx === index) {
          return val;
        }
        return item;
      })
    );
  };

  const handleLocationChange = (selectedOption, val, index) => {

    let newVal = { ...val };

    if (!selectedOption || newVal.message === "MOVEHOME") {
      return;
    }

    newVal = {
      ...newVal,
      locationId: selectedOption,
    };

    formik.setFieldValue(
      "actions",
      formik.values?.actions.map((item, idx) => {
        if (index === idx) {
          return newVal;
        }
        return item;
      })
    );
  };

  return (
    <>
      <>
        <Modal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          backdrop="static"
          keyboard="false"
        >
          <Box
            sx={{
              ...modalstyle1,
              width: isMobile ? "100%" : 800,
              height: isMobile ? "100%" : "auto",
              maxHeight: "94%",
              border: 0,
              borderRadius: "8px",
            }}
          >
            {loader ? (
              <div className="flex flex-col items-center justify-center p-4">
                <Loader />
              </div>
            ) : (
              <div
                className={`Order_modal_wrapper text-white ${
                  isMobile
                    ? "100%"
                    : formik.values?.actions.filter((item) => (item?.actionType === "MOVELOCATION" || item?.actionType === "")).length >= 6
                    ? "h-[800px]"
                    : "h-full"
                } overflow-hidden max-h-[82%]`}
              >
                <div className="p-4 d-flex-center OrderModal_heading_wrapper">
                  <h3 className="Order_modal_h3">{mission?"Edit":"Add"} Mission</h3>
                </div>

                <div className="h-[calc(100%-106px)]">
                  <form className="h-full" onSubmit={formik.handleSubmit}>
                    <div className="h-[calc(100%-124px)]">
                      <div className="relative h-[52px]">
                        <label className=" text-sm absolute top-[-12px] bg-[#272f42] left-[38px] px-2">
                          Mission Name
                        </label>{" "}
                        <input
                          name="name"
                          className="mx-4 px-4 py-2 w-[calc(100%-50px)] text-2xl text-center border-[1px] border-white rounded-md bg-[#282f42] font-bold "
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                        />
                      </div>

                      <div className=" max-h-[calc(100%-116px)] overflow-scroll my-4">
                        {formik.values?.actions.map((action, index) => {

                        if(mission && !(action?.actionType === "MOVELOCATION" || action?.actionType === "")){
                          return null;
                        }

                          let val = action;
                          let isEditable = true;
                          if (action?.message === "MOVEHOME") {
                            isEditable = false;
                          }

                          return (
                            // <div
                            //   className={`${
                            //     index === 0
                            //       ? "border-t-[1px] border-white mt-4"
                            //       : ""
                            //   }`}
                            // >
                            <div
                              className={` mx-4 my-2 grid grid-cols-11 gap-4 pt-4 pb-2 p-2`}
                            >
                              
                                <div
                                  className="col-span-3 w-full rounded-md border-[1px] border-white"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                  }}
                                >
                                  <FormControl
                                    style={{
                                      backgroundColor: "#272e42",
                                      width: "100%",
                                      borderRadius: "0.375rem",
                                      borderColor: "white",
                                      WebkitTextFillColor:
                                        "var(--white) !important",
                                      position: "relative",
                                    }}
                                  >
                                    <label className=" text-sm absolute top-[-12px] bg-[#272f42] left-[12px] px-2">
                                      Action Type
                                    </label>
                                    <Autocomplete
                                      style={{
                                        width: "100%",
                                      }}
                                      placeholder="Enter the Action Type"
                                      options={actionTypes}
                                      getOptionLabel={(option) =>  option?.label}
                                      value={
                                        val?.message
                                          ? actionTypes.filter(
                                              (item) =>
                                                item?.value === val?.message
                                            )[0]
                                          : null
                                      }
                                      onChange={(_, selectedOption) => {
                                        handleActionTypeChange(
                                          selectedOption,
                                          val,
                                          index
                                        );
                                      }}
                                      isOptionEqualToValue={(option, value) =>
                                        option?.label === value?.label
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          InputProps={{
                                            ...params.InputProps,
                                            style: {
                                              borderColor: "white",
                                              color: "#ffffff", // Text color
                                            },
                                            disableUnderline: true, // To remove the underline of the text field
                                          }}
                                          style={{
                                            color: "#000000",
                                            margin: "0",
                                            padding: "5px 5px 5px 10px",
                                            fontSize: "14px",
                                          }}
                                          sx={{
                                            "& .MuiAutocomplete-endAdornment": {
                                              "& .MuiSvgIcon-root": {
                                                color: "white", // Color of the dropdown arrow
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </div>
                                <div className="col-span-2 w-full relative">
                                  <label
                                    htmlFor="startTime"
                                    className=" text-sm absolute top-[-12px] bg-[#272f42] left-[12px] px-2"
                                  >
                                    Start Time
                                  </label>

                                  <input
                                    name="startTime"
                                    className="px-2 h-full text-[16px] py-2.5 text-left border-[1px] w-full border-white rounded-md bg-[#282f42] "
                                    type="text"
                                    placeholder="e.g. 0745"
                                    value={val.startTime}
                                    onChange={(e) => {
                                      handleTimeChange(
                                        e,
                                        index,
                                        action,
                                        "startTime"
                                      );
                                    }}
                                    maxLength="4"
                                    pattern="[0-9]*"
                                  />
                                </div>
                                <div
                                  className="col-span-3 w-[100%] rounded-md border-[1px] border-white"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                  }}
                                >
                                  <FormControl
                                    style={{
                                      backgroundColor: "#272e42",
                                      width: "100%",
                                      borderRadius: "0.375rem",
                                      borderColor: "white",
                                      position: "relative",
                                      WebkitTextFillColor:
                                        "var(--white) !important",
                                    }}
                                  >
                                    <label className=" text-sm absolute top-[-12px] bg-[#272f42] left-[12px] px-2">
                                      Location
                                    </label>

                                    <Autocomplete
                                      style={{
                                        width: "100%",
                                      }}
                                      disableClearable={!isEditable}
                                      options={locationList}
                                      getOptionLabel={(option) =>
                                        option.locationName
                                      }
                                      value={
                                        val?.locationId?._id === ""
                                          ? null
                                          : locationList.find((option) => {
                                              return (
                                                option?._id ===
                                                val?.locationId?._id
                                              );
                                            }) || null
                                      }
                                      onChange={(_, selectedOption) => {
                                        handleLocationChange(
                                          selectedOption,
                                          val,
                                          index
                                        );
                                      }}
                                      isOptionEqualToValue={(option, value) => {
                                        return (
                                          option?.locationName ===
                                          value?.locationName
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          disabled={!isEditable}
                                          InputProps={{
                                            ...params.InputProps,
                                            style: {
                                              borderColor: "white",
                                              color: "#ffffff", // Text color
                                            },
                                            disableUnderline: true, // To remove the underline of the text field
                                          }}
                                          style={{
                                            color: "#000000",
                                            margin: "0",
                                            padding: "5px 5px 5px 10px",
                                            fontSize: "14px",
                                          }}
                                          sx={{
                                            "& .MuiAutocomplete-endAdornment": {
                                              "& .MuiSvgIcon-root": {
                                                color: "white", // Color of the dropdown arrow
                                              },
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </div>
                                <div className="w-full col-span-2 relative">
                                  <label
                                    htmlFor="finishTimeCalculated"
                                    className=" text-sm absolute top-[-12px] bg-[#272f42] left-[12px] px-2"
                                  >
                                    End Time
                                  </label>

                                  <input
                                    name="finishTimeCalculated"
                                    className="px-2 h-full text-[16px] py-2.5 text-left border-[1px] w-full border-white rounded-md bg-[#282f42] "
                                    type="text"
                                    placeholder="e.g. 0745"
                                    value={val.finishTimeCalculated}
                                    onChange={(e) => {
                                      handleTimeChange(
                                        e,
                                        index,
                                        action,
                                        "finishTimeCalculated"
                                      );
                                    }}
                                    maxLength="4"
                                    pattern="[0-9]*"
                                  />
                                </div>
                                <div
                                  className="col-span-1 px-4 w-fit ml-auto mt-2"
                                  onClick={() => {
                                    formik.setFieldValue(
                                      "actions",
                                      formik.values.actions.filter(
                                        (item, idx) => idx !== index
                                      )
                                    );
                                  }}
                                >
                                  <DeleteIcon className="w-5 h-5 cursor-pointer text-[#e96c67]" />
                                </div>
                            </div>
                          );
                        })}
                      </div>

                      <div
                        className="mx-auto h-[34px] px-2 py-1 border-[1px] border-white w-fit rounded-md my-2 hover:cursor-pointer"
                        onClick={() => {
                          formik.values.actions.push(actionBody);
                        }}
                      >
                        Add Action
                      </div>
                    </div>

                    <div className="h-[89px] absolute inset-x-0 bottom-0 flex justify-between px-3 Order_modal_button_wrapper sm:relative">
                      <button
                        onClick={() => setModalOpen(false)}
                        className="!p-[10px_20px] m-[20px] font-bold text-white transition-all border-2 rounded-lg bg-ottonomyDarkBlue border-ottonomyBlue hover:bg-ottonomyBlue"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="transition-all Order_save_button hover:bg-ottonomyBlue"
                        onClick={() => {
                          formik.handleSubmit();
                          setModalOpen(false);
                        }}
                      >
                        Submit{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      </>
    </>
  );
}

export default AddMissionsModal;
