import { useEffect, useState } from "react";
import { TbEdit, TbSignRight } from "react-icons/tb";
import { MdDone } from "react-icons/md";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModel from "./ConfirmationModel";
import { updateMission } from "../API";
import AddMissionsModal from "./AddMissionModal";


export default function MissionCard({ index, mission, locationList }) {
  const [editable, setEditable] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const destructureActions = (actions) => {
    let componentList = [];
    let newActions = actions
    .filter(
      (item) => item.actionType === "MOVELOCATION"
    );
    newActions
      // .filter((item) => item.message !== "MOVEHOME")
      .map((action, index) => {
        if (!action?.locationId) {
          return;
        }
        // let startTimeString = action.startTime.substring(0,2)+":"+action.startTime.substring(2,4);
        let startTimeString =
          index < actions.length - 1
            ? newActions[index + 1]?.startTime?.substring(0, 2) +
              ":" +
              newActions[index + 1]?.startTime?.substring(2, 4)
            : "";

        let finishTimeString =
          action?.finishTimeCalculated?.substring(0, 2) +
          ":" +
          action?.finishTimeCalculated?.substring(2, 4);

        componentList.push(
          <div className="flex flex-col justify-center items-center">
            <div className="bg-white rounded-full w-[10px] h-[10px]"></div>
            <div className="flex flex-col items-center">
            <>
                  <div className="text-sm">
                    {action?.locationId?.locationName}
                  </div>
                  <div className="text-[12px]">{finishTimeString}</div>{" "}
                </>
              {/* {!editable ? (
                <>
                  <div className="text-sm">
                    {action?.locationId?.locationName}
                  </div>
                  <div className="text-[12px]">{finishTimeString}</div>{" "}
                </>
              ) : (
                <>
                  <div className="text-sm">
                    {action?.locationId?.locationName}
                  </div>
                  <input
                    className="px-2 text-[12px] py-1 w-fit text-center border-1 border-white rounded-md bg-[#282f42] "
                    type="time"
                    onChange={formik.handleChange}
                    value={finishTimeString}
                    name="hello"
                  />
                </>
              )} */}
            </div>
          </div>
        );

        // componentList.push(
        //   <div className="flex flex-col justify-center items-center relative">
        //         <div className="bg-white rounded-full w-[10px] h-[10px]"></div>
        //         <div className="w-[130px] flex justify-between absolute top-[-16px]">
        //           <div className="text-[12px]">{finishTimeString}</div>
        //           {(index<newActions.length-1)? <div className="text-[12px]">{startTimeString}</div>:""}
        //         </div>

        //         <div className="flex flex-col items-center">
        //           <div className="text-sm">{action?.locationId?.locationName}</div>
        //         </div>
        //   </div>)
      });
    return componentList;
  };

  const handleDelete = () => {
    mission.isDeleted = true;
    updateMission(mission, mission._id, localStorage.getItem("token"));
    // console.log(mission,'mission');
    // updateMission()
  };

  return (
    <>
      <div className="flex flex-col gap-3 bg-[#1C1E2A] pl-4 pr-4 pb-4 pt-2">
        {modalOpen && (

          <AddMissionsModal
            modalOpen={modalOpen}
            mission={mission}
            setModalOpen={setModalOpen}
            locationList={locationList}
        />
        )}
        {confirmationModalOpen && (
          <ConfirmationModel
          showActionLiveDropdown={false}
            textToDisplay={"Are you sure?"}
            onConfirm={handleDelete}
            showConfirmationModal={confirmationModalOpen}
            setShowConfirmationModal={setConfirmationModalOpen}
          />
        )}
        <div className="w-full flex flex-row justify-between">
        <div className="font-bold">{mission.name}</div>
          {/* {!editable ? (
            <div className="font-bold">{mission.name}</div>
          ) : (
            <input
              className="px-4 py-1 w-[300px] text-center border-1 border-white rounded-md bg-[#282f42] font-bold "
              type="text"
              onChange={formik.handleChange}
              value={formik.values.missionName}
              name="missionName"
            />
          )} */}

          <div className="flex flex-row items-center">
            <div onClick={() => setModalOpen(true)}>
              <TbEdit className="w-5 h-5 cursor-pointer text-ottonomyBlue" />
            </div>
            <div
              className="ml-2"
              onClick={() => setConfirmationModalOpen(true)}
            >
              <DeleteIcon className="w-5 h-5 cursor-pointer text-[#e96c67]" />
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex justify-center">
            <div className="w-full border-t border border-1 border-stone-300 absolute mt-1 z-0"></div>
          </div>
          <div className="flex justify-around z-1">
            {destructureActions(mission.actions)}
          </div>
        </div>
      </div>
    </>
  );
}
